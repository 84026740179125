import { navigate } from "gatsby"
import { isBrowser } from "../utils/browser"

export default () => {
  if (isBrowser()) {
    navigate("/dashboard")
  }

  return null
}
